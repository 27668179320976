import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  partnerProfileAreaContainer: {
    backgroundColor: theme.palette.toolbarBackground.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 60,
    right: 0,
    // height: '320px',
    margin: theme.spacing(2),
    borderRadius: '25px',
    // transform:translateX(-50),
    opacity:0,
    // transition:opacity 1s,transform 0.5s,
    'z-index':10,
  },
  fadein: {
    opacity:1,
    // transform:translateX(0)
  }
}));

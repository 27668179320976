import { useState, useEffect, useRef } from 'react';
import {
  Grid,Button
} from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from './styles';

export default function StaffPhoneArea({ staffPhoneNumber}) {
  const classes = styles();
  const theme = useTheme();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('xs'));
  const [showStaffPhoneNumber, setShowStaffPhoneNumber] = useState(false);
  const onClickCallPhone = () => {
    window.open(`tel:${staffPhoneNumber}`);
  };
  const onShowStaffPhoneNumber = () => {
    setShowStaffPhoneNumber(true);
  };
  return !staffPhoneNumber ? ('') : (isMobileWidth ? (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        variant="text"
        color="success"
        onClick={onClickCallPhone}
        className={classes.textUnderline}
      >
        担当者へ電話する
      </Button>
    </Grid>
  ) : (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          variant="text"
          color="success"
          onClick={onShowStaffPhoneNumber}
         className={classes.textUnderline}
        >
          担当者へ電話する
        </Button>
      </Grid>
     {showStaffPhoneNumber  && (
       <Grid
         container
         direction="column"
         justifyContent="center"
         alignItems="center"
       >
         <div className={classes.marginTop10}>{staffPhoneNumber}</div>
       </Grid>
    )}
  </div>
  ));

}

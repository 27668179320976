import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  root: {
    top: '0',
    background: '#f19c38',
    justifyContent: 'center',
  },
  info: {
    top: '0',
    background: '#418be9',
    justifyContent: 'center',
  },
  action: {
    position: 'absolute',
    right: '0',
    paddingRight: '10px',
  },
  networkStatusIcons: {
    paddingRight: '10px',
  },
  snackBarContent: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

import { useState, useEffect, useRef } from 'react';
import styles from './styles';
export default function PartnerProfileArea({ isProfileOpen, iframeSrc }) {
  const classes = styles();
  const [width, setWidth] = useState('320px');
  const [height, setHeight] = useState('320px');
  const [containerClassName, setContainerClassName] = useState(classes.partnerProfileAreaContainer);
  const [profileRandom, setProfileRandom] = useState(0);
  const elm = useRef(null);
  useEffect(() => {
    setHeight(Math.max(360, window.innerHeight*0.6)+'px');
  }, []);

  useEffect(() => {
    if(isProfileOpen){
      setContainerClassName(classes.partnerProfileAreaContainer + ' ' + classes.fadein)
    }else{
      setContainerClassName(classes.partnerProfileAreaContainer)
    }
  }, [isProfileOpen]);

  useEffect(() => {
    if(!isProfileOpen){
      setProfileRandom(profileRandom+1)
    }
  }, [isProfileOpen]);
  return (
    <div className={containerClassName}>
      <iframe key={profileRandom} id="profile_ifame" width={width} height={height} src={iframeSrc}></iframe>
    </div>
  );
}

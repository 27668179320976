import { makeStyles } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';
import { Height } from '@material-ui/icons';
export default makeStyles((theme) => ({
  waitingRoomContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '25px',
    borderRadius: 5,
    'min-width': '250px',
    "@media only screen and (min-device-width: 375px) and (max-device-width: 1500px) and (orientation: landscape) ":{
      top: '0%',
      width: 'calc(100vw - 30px)',
      transform: 'translate(-50%, -0%)',
      'padding-left': '15px',
      'padding-right': '15px'
    },
    "@media only screen and (min-device-width: 375px) and (max-device-width: 1500px) and (orientation: portrait) ":{
      top: '0%',
      width: 'calc(100vw - 30px)',
      transform: 'translate(-50%, -0%)',
      'padding-left': '15px',
      'padding-right': '15px'
    },
    // height: '90%',
  },
  formControl: {
    width: '100%',
  },
  mediaSources: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    "& .MuiSelect-selectMenu": {
      'white-space': 'normal',
    }
  },
  waitingRoomVideoPreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0px',
    minHeight: '200px',
  },
  deviceContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 5px',
  },
  deviceSettings: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  networkTestContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 5px',
  },
  flex: {
    display: 'flex',
  },
  root: {
    width: '20%',
  },
  margin10: {
    'margin-bottom': '10px',
  },
  marginTop10: {
    'margin-top': '10px',
  },
  marginTop20: {
    'margin-top': '20px',
  },
  errorContainer: {
    color: 'red',
    'padding-right': '30px',
    'padding-left': '30px',
  },
  textUnderline:{
    'text-decoration':'underline',
  },
  paddingRL30: {
    'padding-right': '30px',
    'padding-left': '30px',
  },
  textMini:{
    'font-size': '0.875rem',
  },
}));

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import OT from '@opentok/client';
import useStyles from '../WaitingRoom/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';
import { UserContext } from '../../context/UserContext';
import NetworkTest, { ErrorNames } from 'opentok-network-test-js';
import { useLocation } from "react-router-dom";
import { getPreCallCredentials } from '../../api/fetchCreds';
import StaffPhoneArea from 'components/StaffPhoneArea';
export default function NetworkTestRoom({ location }) {
  const classes = useStyles();
  const { push } = useHistory();
  const { user, setUser } = useContext(UserContext);
  const waitingRoomVideoContainer = useRef();
  const search = useLocation().search;
  const query2 = new URLSearchParams(search);
  const roomToJoin = query2.get("request_id");
  const memberTestFlag = query2.get("member_test")
  const staffMeetingFlag = query2.get("staff_meeting")
  const zweiSitePath = query2.get("zwei_path")
  localStorage.setItem('zweiSitePath',zweiSitePath);
  const [roomName, setRoomName] = useState(roomToJoin || localStorage.getItem('roomname'));
  const meetingType = (!roomToJoin && !memberTestFlag) ? localStorage.getItem('meetingtype') : (memberTestFlag) ? 'self_test' : (staffMeetingFlag) ? 'staff_meeting' : 'member_meeting'
  const [deviseToken, setDeviseToken] = useState(query2.get("token"));
  const theme = useTheme();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('xs'));
  const [checkInProcess, setCheckInProcess] = useState(false);
  const [checkDone, setCheckDone] = useState(false);
  const [checkButtonText, setCheckButtonText] = useState('通信環境確認');
  const [networkMessages, setNetworkMessages] = useState([]);

  const [staffPhoneNumber, setStaffPhoneNumber] = useState();
  const [memberMasquerade, setMemberMasquerade] = useState();
  const [credentials, setCredentials] = useState(null);


  useEffect(() => {
    const text = (checkInProcess) ? 'テスト実施中' : '通信環境確認'
    setCheckButtonText(text)
  }, [checkInProcess]);


  const redirectHttps = React.useCallback(() => {
    const url = window.location.href;
    if (
      url.toString().indexOf('http://') === 0 &&
      url.toString().indexOf('http://localhost') !== 0
    ) {
      window.location.href = window.location.href
        .toString()
        .replace('http://', 'https://');
    } else {
      return;
    }
  }, []);


  const handleCheckClick = () => {
    setCheckInProcess(true)
    let otNetworkTest = new NetworkTest(OT, credentials);
    otNetworkTest.testConnectivity().then((results) => {
      otNetworkTest.testQuality(function updateCallback(stats) {
      }).then((results) => {
        setCheckInProcess(false)
        // This function is called when the quality test is completed.
        console.log('OpenTok quality results', results);
        let publisherSettings = {};
        if (results.video.reason) {
          console.log('Video not supported:', results.video.reason);
          publisherSettings.videoSource = null; // audio-only
          setNetworkMessages([...networkMessages, '映像が表示できません。']);
        } else {
          publisherSettings.frameRate = results.video.recommendedFrameRate;
          publisherSettings.resolution = results.video.recommendedResolution;
        }
        if (!results.audio.supported) {
          console.log('Audio not supported:', results.audio.reason);
          publisherSettings.audioSource = null;
          // video-only, but you probably don't want this -- notify the user?
          setNetworkMessages([...networkMessages, '音声を出力できません。']);
        }
        if (!publisherSettings.videoSource && !publisherSettings.audioSource) {
          // Do not publish. Notify the user.
        } else {
          // Publish to the "real" session, using the publisherSettings object.
        }
        if(results.audio.mos < 2.4 || results.video.mos < 2.4){
          setNetworkMessages([...networkMessages, '通信環境が悪く、オンライン面談を実施できません。']);
          setCheckDone(false);
        }else{
          otNetworkTest=null;
          setNetworkMessages(['十分な通信速度です。面談室へお入りください。']);
          setCheckDone(true);
          if(memberTestFlag && memberTestFlag=='true'){
            push(`/networkresult`);
          }else{
            localStorage.setItem('roomname',roomName);
            localStorage.setItem('meetingtype',meetingType);
            push({
                  pathname:`/waiting/${roomName}`,
                  state: {meetingType: meetingType}
              });
          }

        }
      }).catch((error) => {
        setNetworkMessages([...networkMessages, '通信品質テスト時にエラーが発生しました。']);
        setCheckInProcess(false);
        console.log('OpenTok quality test error', error);
      });
    }).catch(function(error) {
      setNetworkMessages([...networkMessages, '接続テスト時にエラーが発生しました']);
      setCheckInProcess(false);
      console.log('OpenTok connectivity test error', error);
    });
  };


  useEffect(() => {
    redirectHttps();
  }, [redirectHttps]);

  useEffect(() => {
    getPreCallCredentials(roomName,deviseToken,meetingType)
      .then(({ data }) => {
        console.log('watitingroom getCredentials')
        setCredentials({
          apiKey: data.apiKey,
          sessionId: data.sessionId,
          token: data.token,
        });
        // setMatchmakingAt(new Date(data.matchmakingAt));
        setStaffPhoneNumber(data.staffPhoneNumber);
        setMemberMasquerade(data.member_masquerade);
      })
      .catch((err) => {
        console.log(err);
        setNetworkMessages([...networkMessages, '面談が存在しません']);
        window.setTimeout(function(){
          window.location.href = process.env.REACT_APP_API_URL_PRODUCTION;
        }, 3000);
      });
  }, [roomName]);
  return (
    <>
      <div className={classes.waitingRoomContainer}>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.margin10}
        >
          <div className={classes.paddingRL30 }>
            {memberTestFlag ?
              `通信環境テストを行います。通信環境確認ボタンを押してしばらくお待ちください。`
              : `通信環境確認ボタンを押してしばらくお待ちください。確認完了後、入室画面に移動します。`
            }
          </div>
        </Grid>

        {networkMessages.length > 0 && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.margin10}
          >
            <div className={(checkDone) ? classes.paddingRL15 : classes.errorContainer}>{networkMessages.join('')}</div>
          </Grid>
        )}

        {!checkDone && credentials && (
            <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.margin10}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleCheckClick}
              disabled={checkInProcess }
            >
              {checkButtonText}
            </Button>
          </Grid>
        )}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.margin10}
        >
          <div className={classes.paddingRL30 }>通信環境を維持するために、別アプリ、別ブラウザ、ブラウザ内の関係ないタブ等を終了して利用ください。</div>
          {memberMasquerade && (
            <div className={`${classes.paddingRL30} ${classes.errorContainer}`}>代行ログイン中です。必要な操作が終了したら、確実にログアウトしてタブを閉じてください。会員が機能を利用できない状況となります。</div>
          )}
        </Grid>
        <StaffPhoneArea staffPhoneNumber={staffPhoneNumber} />
      </div>
    </>
  );
}

import { useState, useEffect, useRef } from 'react';
import styles from './styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import RouterIcon from '@material-ui/icons/Router';
import Snackbar from '@material-ui/core/Snackbar';

export default function FlashMessage({messageText }) {
  const classes = styles();
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (<>
    <div>
      <Snackbar
        open={open}
        onClose={handleClose}
        className={classes.anchorOriginTopCenter}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        ContentProps={{
          classes: {
            root: classes.root,
            action: classes.action
          }
        }}

        message={
          <div className={classes.snackBarContent}>
            {messageText}
          </div>
        }
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
          <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      />
    </div>
  </>) ;

}

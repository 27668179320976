import { useState, useEffect, useRef } from 'react';
import styles from './styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import RouterIcon from '@material-ui/icons/Router';
import Snackbar from '@material-ui/core/Snackbar';

export default function CountdownTimer({ meetingEndAt ,endMeeting}) {
  const classes = styles();
  const [isShow, setIsShow] = useState(false);
  const [countDown, setCountDown] = useState(null);
  const showTimerTime = 1000*60*10
  useEffect(() => {
    if(meetingEndAt){
      const interval = setInterval(() => {
        let diff =meetingEndAt- new Date().getTime()
        if( showTimerTime > diff && diff> 0){
          if(!isShow){setIsShow(true)}
          if(diff>1000*60){
            setCountDown(Math.floor(diff / 60000)+1+'分')
          }else{
            setCountDown(Math.floor(diff / 1000)+'秒')
          }
        }else if(diff < 0){
          clearInterval(interval);
          endMeeting();
        }
      }, 1000);
    }
  }, [meetingEndAt]);

  return isShow ? (

    <div>
      <Snackbar
        open={true}
        className={classes.anchorOriginTopCenter}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        ContentProps={{
          classes: {
            root: classes.root,
            action: classes.action
          }
        }}

        message={
          <div className={classes.snackBarContent}>
            終了まであと{countDown}です
          </div>
        }
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
          >
          </IconButton>
        }
      />
    </div>



  ) : <></>;

}

import { useHistory,useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { useContext, useEffect, useRef, useState } from 'react';
import { getCredentials } from '../../api/fetchCreds';
import { setMeetingStartAt } from '../../api/setMeetingStartAt';
import styles from './styles.js';
import useRoom from '../../hooks/useRoom';
import { UserContext } from '../../context/UserContext';

import ToolBar from 'components/ToolBar';

import NetworkToast from 'components/NetworkToast';
import useScreenSharing from '../../hooks/useScreenSharing';
import PartnerProfileArea from 'components/PartnerProfileArea';
import CountdownTimer from 'components/CountdownTimer';
import FlashMessage from 'components/FlashMessage';

export default function VideoRoom() {
  const { user } = useContext(UserContext);
  const [credentials, setCredentials] = useState(null);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [participantInfo, setParticipantInfo] = useState({});
  const [meetingEndAt, setMeetingEndAt] = useState(null);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [hasOaiteCome, setHasOaiteCome] = useState(false);
  const [isOaiteNow, setIsOaiteNow] = useState(false);
  const [error, setError] = useState(null);
  const {
    createCall,
    room,
    participants,
    connected,
    networkStatus,
    cameraPublishing,
    localParticipant,
  } = useRoom();
  const { isScreenSharing, startScreenSharing, stopScreenSharing } =
    useScreenSharing({ room });
  const roomContainer = useRef();
  const classes = styles();
  let { roomName } = useParams();
  const location = useLocation();
  const meetingType=location.state.meetingType;
  const [meetingName, setMeetingName] = useState(null);
  useEffect(() => {
    if (meetingType=='member_meeting'){
      setMeetingName('お見合い');
    }else if(meetingType=='staff_meeting'){
      setMeetingName('面談');
    }
  }, [meetingType]);
  const onViewProfile = () => {
    setIsProfileOpen(!isProfileOpen);
  };
  const { push } = useHistory();
  const endMeeting = () => {
    if (room) {
      push({
            pathname:`${roomName}/${room.roomId}/end`,
            state: {
              redirect_url: redirectUrl,
              meeting_name: meetingName,
            }
        });
      room.leave();
    }
  };

  useEffect(() => {
    getCredentials(roomName,meetingType)
      .then(({ data }) => {
        setCredentials({
          apikey: data.apiKey,
          sessionId: data.sessionId,
          token: data.token,
        });
        setIframeSrc(data.detailPageUrl);
        setRedirectUrl(data.redirectUrl)
        setParticipantInfo({
          myId: data.myId,
          memberIds: [data.requestMemberId,data.requestPartnerId].filter(Boolean)
        });
        if(data.meetingEndAt){
          setMeetingEndAt( new Date(data.meetingEndAt))
        }
      })
      .catch((err) => {
        setError(err);
        console.log(err);
      });
  }, [roomName]);

  useEffect(() => {
    if (credentials) {
      createCall(
        credentials,
        roomContainer.current,
        user.userName,
        user.videoEffects,
        {
          ...user.defaultSettings,
        }
      );
    }
  }, [createCall, credentials, user]);
  // お相手の入室状況判定
  useEffect(() => {
    let member_ids = participants.map(p => p.connectionData);
    if( participantInfo && participantInfo.memberIds){
      member_ids.push(participantInfo.myId)
      let all_members_flag =true;
      //2人以上参加していること
      if(member_ids.length < 2){all_members_flag=false}
      //memberが参加していること
      (participantInfo.memberIds || []).forEach(function(member_id){
        if(!member_ids.includes(member_id)){all_members_flag=false}
      });
      if(all_members_flag){

        setHasOaiteCome(true);
        setIsOaiteNow(true);
      }else{
        setIsOaiteNow(false);
      }
    }
  }, [participants]);

  // meeting終了時間がセットされていない場合、参加メンバーが揃ったタイミングで終了時間をセット
  useEffect(() => {
    if(hasOaiteCome && !meetingEndAt){
      setMeetingStartAt(roomName,meetingType)
        .then(({ data }) => {
          if(!meetingEndAt){
            setMeetingEndAt( new Date(data.meetingEndAt))
          }
        })
        .catch((err) => {
          setError(err);
          console.log(err);
        });
    }
  }, [hasOaiteCome]);

  //暫定対応で高さ指定
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);
  if (error)
    return (
      <div className={classes.errorContainer}>
        There was an error fetching the data from the server
      </div>
    );

  return (
    <div id="callContainer" className={classes.callContainer}>
      <div
        id="roomContainer"
        className={classes.roomContainer}
        ref={roomContainer}
      >
        <NetworkToast networkStatus={networkStatus} />
        { hasOaiteCome && !isOaiteNow && (<FlashMessage messageText={'お相手が退室しました。'} />)}
        <CountdownTimer meetingEndAt={meetingEndAt} endMeeting={endMeeting} />

        <div
          id="screenSharingContainer"
          className={classes.screenSharingContainer}
        >
          {isScreenSharing && (
            <div className={classes.screenSharingOverlay}>
              You Are Screensharing
            </div>
          )}
        </div>
      </div>
      <div id="publisher-pip-container" className={classes.publisherPipContainer}>
          <div id="publisher-video-container" className={classes.publisherVideoContainer}></div>
      </div>
      <PartnerProfileArea
        isProfileOpen={isProfileOpen}
        iframeSrc={iframeSrc}
      />
      <ToolBar
        room={room}
        participants={participants}
        localParticipant={localParticipant}
        connected={connected}
        cameraPublishing={cameraPublishing}
        isScreenSharing={isScreenSharing}
        startScreenSharing={startScreenSharing}
        stopScreenSharing={stopScreenSharing}
        isProfileOpen={isProfileOpen}
        onViewProfile={onViewProfile}
        endMeeting={endMeeting}
        meetingType={meetingType}
        meetingName={meetingName}
      ></ToolBar>
    </div>
  );
}

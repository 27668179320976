import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  // countdownTimerDiv: {
  //   position: 'absolute',
  //   // 'z-index': 10000,
  //   top: '10px'
  // },
  // countdownTimerContainer: {
  //   'background-color': '#e95d44',
  //   width: '360px',
  //   display: 'flex',
  //   padding: '10px 0',
  //   'justify-content': 'center',
  //   'border-radius': '30px',
  //   color: '#fff',
  // }
  root: {
    top: '0',
    background: '#f19c38',
    justifyContent: 'center',
  },
  info: {
    top: '0',
    background: '#418be9',
    justifyContent: 'center',
  },
  action: {
    position: 'absolute',
    right: '0',
    paddingRight: '10px',
  },
  networkStatusIcons: {
    paddingRight: '10px',
  },
  snackBarContent: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

import WebAssetIcon from '@mui/icons-material/WebAsset';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';


import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import useDevices from '../../hooks/useDevices';

import ButtonGroup from '@material-ui/core/ButtonGroup';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import React from 'react';
import styles from './styles.js';
import { UserContext } from '../../context/UserContext';

export default function PartnerProfileButton({
  onViewProfile,
  isProfileOpen,
  classes,

}) {
  const title = isProfileOpen ? 'プロフィール非表示' : 'プロフィール表示';
  // const { deviceInfo } = useDevices();
  // const [devicesAvailable, setDevicesAvailable] = React.useState(null);
  // const [options, setOptions] = React.useState([]);
  // const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  // const [selectedIndex, setSelectedIndex] = React.useState(0);
  // const localClasses = styles();
  // const { user } = React.useContext(UserContext);

  // React.useEffect(() => {
  //   setDevicesAvailable(deviceInfo.videoInputDevices);
  //   if (cameraPublishing) {
  //     const currentDeviceId = getVideoSource()?.deviceId;
  //
  //     const IndexOfSelectedElement = devicesAvailable.indexOf(
  //       devicesAvailable.find((e) => e.deviceId === currentDeviceId)
  //     );
  //     setSelectedIndex(IndexOfSelectedElement);
  //   }
  // }, [cameraPublishing, getVideoSource, deviceInfo, devicesAvailable]);
  //
  // React.useEffect(() => {
  //   if (devicesAvailable && !user.videoEffects.backgroundBlur) {
  //     const videoDevicesAvailable = devicesAvailable.map((e) => {
  //       return e.label;
  //     });
  //     setOptions(videoDevicesAvailable);
  //   }
  //   if (user.videoEffects.backgroundBlur)
  //     setOptions(['Not available with Background Blurring']);
  // }, [devicesAvailable, user.videoEffects.backgroundBlur]);
  //
  // const handleChangeVideoSource = (event, index) => {
  //   setSelectedIndex(index);
  //   setOpen(false);
  //   const videoDeviceId = devicesAvailable.find(
  //     (device) => device.label === event.target.textContent
  //   ).deviceId;
  //   changeVideoSource(videoDeviceId);
  // };
  //
  // const handleToggle = (e) => {
  //   setOpen((prevOpen) => !prevOpen);
  // };
  //
  // const handleClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }
  //   setOpen(false);
  // };

  return (
    <>
      <ButtonGroup
        className={classes.groupButton}
        disableElevation
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Tooltip title={title} aria-label="add">
          <IconButton
            onClick={onViewProfile}
            edge="start"
            aria-label="WebAssetIcon"
            size="small"
            className={classes.arrowButton}
          >
            {!isProfileOpen ? <WebAssetIcon /> : <WebAssetOffIcon />}
          </IconButton>
        </Tooltip>
      </ButtonGroup>

      {/*<Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 101 }} // todo temporary fix for a bug in MP 0.1.5
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleChangeVideoSource(event, index)}
                      classes={{
                        selected: localClasses.selected,
                        root: localClasses.root,
                      }}
                      disabled={user.videoEffects.backgroundBlur}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>*/}
    </>
  );
}

import axios from 'axios';
axios.defaults.withCredentials = true;

let API_URL = `${process.env.REACT_APP_API_URL_DEVELOPMENT}`;
let basic_param = {};
if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_API_URL_PRODUCTION
) {
  API_URL = `${process.env.REACT_APP_API_URL_PRODUCTION}`;
  if(process.env.REACT_APP_API_BASIC_AUTH_USERNAME && process.env.REACT_APP_API_BASIC_AUTH_PASSWORD){
    basic_param = {auth: {username: process.env.REACT_APP_API_BASIC_AUTH_USERNAME,password: process.env.REACT_APP_API_BASIC_AUTH_PASSWORD}}
  }
}

const getCredentials = async (id,meeting_type) => {
  if(meeting_type=='staff_meeting'){
    return axios.get(`${API_URL}/api/v1/online_staff_meetings/${id}/online_omiai_session`,basic_param);
  }else{
    return axios.get(`${API_URL}/api/v1/requests/${id}/meetings/online_omiai_session`,basic_param);
  }

};

const getPreCallCredentials = async (id,token,meeting_type) => {
  if (meeting_type=='self_test'){
    return axios.get(`${API_URL}/api/v1/meetings/precall_session?token=${token}`,basic_param);
  }else if(meeting_type=='staff_meeting'){
    return axios.get(`${API_URL}/api/v1/online_staff_meetings/${id}/online_omiai_session?pre_call=true&token=${token}`,basic_param);
  }else{
    return axios.get(`${API_URL}/api/v1/requests/${id}/meetings/online_omiai_session?pre_call=true&token=${token}`,basic_param);
  }

};

export { getCredentials,getPreCallCredentials };

import axios from 'axios';
axios.defaults.withCredentials = true;

let API_URL = `${process.env.REACT_APP_API_URL_DEVELOPMENT}`;
let basic_param = {};
if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_API_URL_PRODUCTION
) {
  API_URL = `${process.env.REACT_APP_API_URL_PRODUCTION}`;
  if(process.env.REACT_APP_API_BASIC_AUTH_USERNAME && process.env.REACT_APP_API_BASIC_AUTH_PASSWORD){
    basic_param = {auth: {username: process.env.REACT_APP_API_BASIC_AUTH_USERNAME,password: process.env.REACT_APP_API_BASIC_AUTH_PASSWORD}}
  }
}

export const setMeetingStartAt = async (model_id,meeting_type) => {
  if(meeting_type=='staff_meeting'){
    return axios.get(`${API_URL}/api/v1/online_staff_meetings/${model_id}/set_meeting_start_at`,basic_param);    
  }else{
    return axios.get(`${API_URL}/api/v1/requests/${model_id}/meetings/set_meeting_start_at`,basic_param);
  }

};

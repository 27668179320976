import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import * as VideoExpress from '@vonage/video-express';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OT from '@opentok/client'; //adf
import useStyles from '../WaitingRoom/styles';
import usePreviewPublisher from '../../hooks/usePreviewPublisher';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';
import AudioSettings from '../AudioSetting';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import VideoSettings from '../VideoSetting';
import DeviceAccessAlert from '../DeviceAccessAlert';
import { UserContext } from '../../context/UserContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DEVICE_ACCESS_STATUS } from './../constants';
import useBackgroundBlur from '../../hooks/useBackgroundBlur';
import { useLocation } from "react-router-dom";
export default function NetWorkTestResultRoom({ location }) {
  const { stopEffect, startBackgroundBlur, isSupported } = useBackgroundBlur();
  const classes = useStyles();
  const { push } = useHistory();
  const { user, setUser } = useContext(UserContext);
  const waitingRoomVideoContainer = useRef();
  const theme = useTheme();
  const zweiSiteUrl = process.env.REACT_APP_API_URL_PRODUCTION;
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('xs'));
  const [localAudio, setLocalAudio] = useState(
    user.defaultSettings.publishAudio
  );
  const [localVideo, setLocalVideo] = useState(
    user.defaultSettings.publishVideo
  );
  const [localVideoSource, setLocalVideoSource] = useState(undefined);
  const [localAudioSource, setLocalAudioSource] = useState(undefined);
  const [localAudioOutput, setLocalAudioOutput] = useState(undefined);
  /* const [devices, setDevices] = useState(null); */
  let [audioDevice, setAudioDevice] = useState('');
  let [videoDevice, setVideoDevice] = useState('');
  let [audioOutputDevice, setAudioOutputDevice] = useState('');
  const [backgroundBlur, setBackgroundBlur] = useState(
    user.videoEffects.backgroundBlur
  );
  const {
    createPreview,
    destroyPreview,
    previewPublisher,
    logLevel,
    previewMediaCreated,
    deviceInfo,
    accessAllowed,
  } = usePreviewPublisher();

  const handleVideoSource = React.useCallback(
    (e) => {
      const videoDeviceId = e.target.value;
      setVideoDevice(e.target.value);
      previewPublisher.setVideoDevice(videoDeviceId);
      setLocalVideoSource(videoDeviceId);
    },
    [previewPublisher, setVideoDevice, setLocalVideoSource]
  );

  const handleAudioSource = React.useCallback(
    (e) => {
      const audioDeviceId = e.target.value;
      setAudioDevice(audioDeviceId);
      previewPublisher.setAudioDevice(audioDeviceId);
      setLocalAudioSource(audioDeviceId);
    },
    [previewPublisher, setAudioDevice, setLocalAudioSource]
  );

  const handleAudioOutput = React.useCallback(
    (e) => {
      const audioOutputId = e.target.value;
      setAudioOutputDevice(audioOutputId);
      // await VideoExpress.setAudioOutputDevice(audioOutputId);
      setLocalAudioOutput(audioOutputId);
    },
    [setLocalAudioOutput, setAudioOutputDevice]
  );

  const handleAudioChange = React.useCallback((e) => {
    setLocalAudio(e.target.checked);
  }, []);

  const handleVideoChange = React.useCallback((e) => {
    setLocalVideo(e.target.checked);
  }, []);

  const handleChangeBackgroundBlur = React.useCallback(async () => {
    console.log('handleChangeBackgroundBlur!!!')
    try {
      if (backgroundBlur) {
        setBackgroundBlur(false);
        destroyPreview();
        stopEffect();
        createPreview(waitingRoomVideoContainer.current, {
          videoSource: localVideoSource,
        });
      } else {
        setBackgroundBlur(true);
        destroyPreview();
        const outputVideoStream = await startBackgroundBlur(videoDevice);
        console.log(outputVideoStream);
        createPreview(waitingRoomVideoContainer.current, {
          videoSource: outputVideoStream.getVideoTracks()[0],
          mirror: true,
        });
      }
    } catch (e) {
      console.log(`Could not send background blurring - ${e}`);
    }
  }, [
    backgroundBlur,
    destroyPreview,
    stopEffect,
    createPreview,
    localVideoSource,
    videoDevice,
    startBackgroundBlur,
  ]);


  useEffect(() => {
    if (
      localAudio !== user.defaultSettings.publishAudio ||
      localVideo !== user.defaultSettings.publishVideo ||
      localAudioSource !== user.defaultSettings.audioSource ||
      localVideoSource !== user.defaultSettings.videoSource ||
      backgroundBlur !== user.videoEffects.backgroundBlur ||
      localAudioOutput !== user.defaultSettings.audioOutput
    ) {
      setUser({
        ...user,
        videoEffects: {
          backgroundBlur: backgroundBlur,
          videoSourceId: localVideoSource,
        },
        defaultSettings: {
          publishAudio: localAudio,
          publishVideo: localVideo,
          audioSource: localAudioSource,
          videoSource: localVideoSource,
          audioOutput: localAudioOutput,
        },
      });
    }
  }, [
    localAudio,
    localVideo,
    user,
    setUser,
    localAudioSource,
    localVideoSource,
    backgroundBlur,
    localAudioOutput,
  ]);

  useEffect(() => {
    if (previewPublisher && previewMediaCreated && deviceInfo) {
      previewPublisher.getAudioDevice().then((currentAudioDevice) => {
        setAudioDevice(currentAudioDevice.deviceId);
      });
      const currentVideoDevice = previewPublisher.getVideoDevice();
      setVideoDevice(currentVideoDevice.deviceId);

      VideoExpress.getActiveAudioOutputDevice().then(
        (currentAudioOutputDevice) => {
          setAudioOutputDevice(currentAudioOutputDevice.deviceId);
        }
      );
    }
  }, [
    deviceInfo,
    previewPublisher,
    setAudioDevice,
    setVideoDevice,
    previewMediaCreated,
    setAudioOutputDevice,
  ]);

  useEffect(() => {
    if (previewPublisher) {
      if (localAudio && !previewPublisher.isAudioEnabled()) {
        previewPublisher.enableAudio();
      } else if (!localAudio && previewPublisher.isAudioEnabled()) {
        previewPublisher.disableAudio();
      }
    }
  }, [localAudio, previewPublisher]);

  useEffect(() => {
    if (previewPublisher) {
      if (localVideo && !previewPublisher.isVideoEnabled()) {
        previewPublisher.enableVideo();
      } else if (!localVideo && previewPublisher.isVideoEnabled()) {
        previewPublisher.disableVideo();
      }
    }
  }, [localVideo, previewPublisher]);

  useEffect(() => {
    console.log('createPreview, destroyPreview, stopEffect')
    if (waitingRoomVideoContainer.current) {
      createPreview(waitingRoomVideoContainer.current);
    }

    return () => {
      stopEffect();
      destroyPreview();
    };
  }, [createPreview, destroyPreview, stopEffect]);
  useEffect(() => {
    if(isSupported){
      setBackgroundBlur(true);
      handleChangeBackgroundBlur();
    }
  }, []);

const backToZweiSite = () => {
  let path = localStorage.getItem('zweiSitePath');
  let redirect_url = (path && path.includes('/')) ? zweiSiteUrl + path : zweiSiteUrl
  window.location.assign(redirect_url);
};

  return (
    <>
      <div className={classes.waitingRoomContainer}>
        <div className={` ${classes.textMini}`}>十分な通信環境です。</div>
        <div className={` ${classes.textMini}`}>マイク・カメラがONになっていることを確認ください。</div>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div>
            <div
              id="waiting-room-video-container"
              className={classes.waitingRoomVideoPreview}
              ref={waitingRoomVideoContainer}
            ></div>
            <div className={classes.deviceContainer}>
              <AudioSettings
                className={classes.deviceSettings}
                hasAudio={localAudio}
                onAudioChange={handleAudioChange}
              />
              <LinearProgress variant="determinate" value={logLevel} />
              <VideoSettings
                className={classes.deviceSettings}
                hasVideo={localVideo}
                onVideoChange={handleVideoChange}
              />
            </div>
          </div>
          <form className={classes.form} noValidate>
            <div className={classes.mediaSources}>
              {deviceInfo && previewMediaCreated  && (
                <>

                  <div className={` ${classes.textMini}`}>「マイク・音声の設定」</div>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      マイク
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={audioDevice}
                      onChange={handleAudioSource}
                    >
                      {deviceInfo.audioInputDevices.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId} className={classes.whiteSpaceNormal}>
                          {device.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <InputLabel id="video">音声</InputLabel>
                    {deviceInfo.audioOutputDevices && (
                      <Select
                        labelId="video"
                        id="demo-simple-select"
                        value={audioOutputDevice}
                        onChange={handleAudioOutput}
                      >
                        {deviceInfo.audioOutputDevices.map((device) => (
                          <MenuItem
                            key={device.deviceId}
                            value={device.deviceId}
                          >
                            {device.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </>
              )}

            </div>
          </form>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.margin10}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={backToZweiSite}
          >
            サイトに戻る
          </Button>
        </Grid>

      </div>
    </>
  );
}
